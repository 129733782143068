import revive_payload_client_4sVQNw7RlN from "/var/www/jobeek/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/jobeek/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/jobeek/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/jobeek/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/jobeek/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/jobeek/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/jobeek/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/jobeek/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/jobeek/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/jobeek/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_client_z0HYv5s9Su from "/var/www/jobeek/node_modules/@bg-dev/nuxt-fcm/dist/runtime/plugins/firebase.client.js";
import plugin_PSbK9A48ZQ from "/var/www/jobeek/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import device_VEtfIepYun from "/var/www/jobeek/plugins/device.js";
import formatNumber_RGZB4gXTbG from "/var/www/jobeek/plugins/formatNumber.js";
import i18n_sVHQBgnb3t from "/var/www/jobeek/plugins/i18n.js";
import moment_lgOtqBZZj3 from "/var/www/jobeek/plugins/moment.js";
import sentry_client_shVUlIjFLk from "/var/www/jobeek/plugins/sentry.client.ts";
import tinymc_editor_client_ZIy3liRr0m from "/var/www/jobeek/plugins/tinymc-editor.client.js";
import vClickOutside_m9zfvNnopr from "/var/www/jobeek/plugins/vClickOutside.js";
import vee_validate_client_x6euUz9Rze from "/var/www/jobeek/plugins/vee-validate.client.js";
import vue3_toastify_OGYNDsiW9E from "/var/www/jobeek/plugins/vue3-toastify.ts";
import vueNumberFormat_3pPmUvWNR6 from "/var/www/jobeek/plugins/vueNumberFormat.js";
import vueTelInput_OfB3YnbU52 from "/var/www/jobeek/plugins/vueTelInput.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  firebase_client_z0HYv5s9Su,
  plugin_PSbK9A48ZQ,
  device_VEtfIepYun,
  formatNumber_RGZB4gXTbG,
  i18n_sVHQBgnb3t,
  moment_lgOtqBZZj3,
  sentry_client_shVUlIjFLk,
  tinymc_editor_client_ZIy3liRr0m,
  vClickOutside_m9zfvNnopr,
  vee_validate_client_x6euUz9Rze,
  vue3_toastify_OGYNDsiW9E,
  vueNumberFormat_3pPmUvWNR6,
  vueTelInput_OfB3YnbU52
]