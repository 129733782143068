import { default as _4042MvVgpq7xybMeta } from "/var/www/jobeek/pages/4042.vue?macro=true";
import { default as aboutCcPjZaxtPcMeta } from "/var/www/jobeek/pages/about.vue?macro=true";
import { default as indexf6DwU0g2z8Meta } from "/var/www/jobeek/pages/advice/[category]/[slug]/index.vue?macro=true";
import { default as _91category_93EAU2jrtOnSMeta } from "/var/www/jobeek/pages/advice/[category].vue?macro=true";
import { default as adviceXEX4nH9dCBMeta } from "/var/www/jobeek/pages/advice.vue?macro=true";
import { default as cabinetoOOs8zDUz2Meta } from "/var/www/jobeek/pages/cabinet.vue?macro=true";
import { default as componentdrKoO03tqqMeta } from "/var/www/jobeek/pages/component.vue?macro=true";
import { default as courseswdZH93RuiXMeta } from "/var/www/jobeek/pages/courses.vue?macro=true";
import { default as create_45resumeuzRQORG8PtMeta } from "/var/www/jobeek/pages/create-resume.vue?macro=true";
import { default as create_45subscriptionDX9eNUgN5cMeta } from "/var/www/jobeek/pages/create-subscription.vue?macro=true";
import { default as create_45vacancypTVmfYRkK8Meta } from "/var/www/jobeek/pages/create-vacancy.vue?macro=true";
import { default as resumess2xx6RtPZYMeta } from "/var/www/jobeek/pages/favorite/resumes.vue?macro=true";
import { default as vacanciesNYSpdQ0KkzMeta } from "/var/www/jobeek/pages/favorite/vacancies.vue?macro=true";
import { default as favorites_45has_45favsuwBD9LuN2oMeta } from "/var/www/jobeek/pages/favorites-has-favs.vue?macro=true";
import { default as forgot_45passwordRYeaUXs8PUMeta } from "/var/www/jobeek/pages/forgot-password.vue?macro=true";
import { default as indexk3sXjUme2EMeta } from "/var/www/jobeek/pages/index.vue?macro=true";
import { default as _91id_93mOFJz6GUqFMeta } from "/var/www/jobeek/pages/my-resume/[id].vue?macro=true";
import { default as indexa3H0PMcD9wMeta } from "/var/www/jobeek/pages/my-resumes/index.vue?macro=true";
import { default as my_45subscriptionsjFRCr9yuxyMeta } from "/var/www/jobeek/pages/my-subscriptions.vue?macro=true";
import { default as index5TpmIxbRGwMeta } from "/var/www/jobeek/pages/my-vacancies/index.vue?macro=true";
import { default as _91id_93m5nUeFMKmJMeta } from "/var/www/jobeek/pages/my-vacancy/[id].vue?macro=true";
import { default as indexXnIptCkVojMeta } from "/var/www/jobeek/pages/notification/index.vue?macro=true";
import { default as email_45verifyjYxHyKdxtaMeta } from "/var/www/jobeek/pages/profile/email-verify.vue?macro=true";
import { default as index1CwYgWfJeiMeta } from "/var/www/jobeek/pages/profile/employer/index.vue?macro=true";
import { default as indexhYrYYza16XMeta } from "/var/www/jobeek/pages/profile/index.vue?macro=true";
import { default as negotiationsh30BoCBNzcMeta } from "/var/www/jobeek/pages/profile/negotiations.vue?macro=true";
import { default as index0GolaRfB5FMeta } from "/var/www/jobeek/pages/profile/seeker/index.vue?macro=true";
import { default as service_45verifyZjfnSpK1efMeta } from "/var/www/jobeek/pages/profile/service-verify.vue?macro=true";
import { default as service_45verify2V0YKjepXjuMeta } from "/var/www/jobeek/pages/profile/service-verify2.vue?macro=true";
import { default as responseslPj1796Qz1Meta } from "/var/www/jobeek/pages/responses.vue?macro=true";
import { default as resultsmci3uAcIVUMeta } from "/var/www/jobeek/pages/results.vue?macro=true";
import { default as indext4hlOdTlQTMeta } from "/var/www/jobeek/pages/resumes/[slug]/index.vue?macro=true";
import { default as resumesUPmgvq1XsWMeta } from "/var/www/jobeek/pages/resumes.vue?macro=true";
import { default as resumesNohyRVAoYfMeta } from "/var/www/jobeek/pages/search/resumes.vue?macro=true";
import { default as vacanciesC5XByqzNF7Meta } from "/var/www/jobeek/pages/search/vacancies.vue?macro=true";
import { default as search_45phoneolQJAwwrxqMeta } from "/var/www/jobeek/pages/services/search-phone.vue?macro=true";
import { default as sign_45inrAflOYMRw3Meta } from "/var/www/jobeek/pages/sign-in.vue?macro=true";
import { default as sign_45upDlHGZrO7PBMeta } from "/var/www/jobeek/pages/sign-up.vue?macro=true";
import { default as createZf7M3DH9hzMeta } from "/var/www/jobeek/pages/subscriptions/create.vue?macro=true";
import { default as _91id_93FS5nyDXgsKMeta } from "/var/www/jobeek/pages/subscriptions/edit/[id].vue?macro=true";
import { default as indexoT4Ah6mmBYMeta } from "/var/www/jobeek/pages/subscriptions/index.vue?macro=true";
import { default as supporta3wqrQpgmRMeta } from "/var/www/jobeek/pages/support.vue?macro=true";
import { default as test4cWdcuvVefMeta } from "/var/www/jobeek/pages/test.vue?macro=true";
import { default as indexgHP8KyztovMeta } from "/var/www/jobeek/pages/vacancies/[slug]/index.vue?macro=true";
import { default as favoriteBzu4WSbQjfMeta } from "/var/www/jobeek/pages/vacancies/favorite.vue?macro=true";
import { default as vacancykPi575YovZMeta } from "/var/www/jobeek/pages/vacancy.vue?macro=true";
import { default as veehNZyhNq2OCMeta } from "/var/www/jobeek/pages/vee.vue?macro=true";
import { default as vee2C7nHia3atvMeta } from "/var/www/jobeek/pages/vee2.vue?macro=true";
import { default as vee3VgQIHwRuLFMeta } from "/var/www/jobeek/pages/vee3.vue?macro=true";
import { default as your_45favorites60jTZFwHkVMeta } from "/var/www/jobeek/pages/your-favorites.vue?macro=true";
import { default as your_45messagesbN7w0HLi2hMeta } from "/var/www/jobeek/pages/your-messages.vue?macro=true";
import { default as your_45responsesLtVEAHhUfrMeta } from "/var/www/jobeek/pages/your-responses.vue?macro=true";
import { default as your_45resumesn5YzvAc1YYMeta } from "/var/www/jobeek/pages/your-resumes.vue?macro=true";
export default [
  {
    name: "4042",
    path: "/4042",
    component: () => import("/var/www/jobeek/pages/4042.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: aboutCcPjZaxtPcMeta || {},
    component: () => import("/var/www/jobeek/pages/about.vue")
  },
  {
    name: "advice",
    path: "/advice",
    component: () => import("/var/www/jobeek/pages/advice.vue"),
    children: [
  {
    name: "advice-category",
    path: ":category()",
    meta: _91category_93EAU2jrtOnSMeta || {},
    component: () => import("/var/www/jobeek/pages/advice/[category].vue"),
    children: [
  {
    name: "advice-category-slug",
    path: ":slug()",
    meta: indexf6DwU0g2z8Meta || {},
    component: () => import("/var/www/jobeek/pages/advice/[category]/[slug]/index.vue")
  }
]
  }
]
  },
  {
    name: "cabinet",
    path: "/cabinet",
    meta: cabinetoOOs8zDUz2Meta || {},
    component: () => import("/var/www/jobeek/pages/cabinet.vue")
  },
  {
    name: "component",
    path: "/component",
    component: () => import("/var/www/jobeek/pages/component.vue")
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/var/www/jobeek/pages/courses.vue")
  },
  {
    name: "create-resume",
    path: "/create-resume",
    component: () => import("/var/www/jobeek/pages/create-resume.vue")
  },
  {
    name: "create-subscription",
    path: "/create-subscription",
    component: () => import("/var/www/jobeek/pages/create-subscription.vue")
  },
  {
    name: "create-vacancy",
    path: "/create-vacancy",
    component: () => import("/var/www/jobeek/pages/create-vacancy.vue")
  },
  {
    name: "favorite-resumes",
    path: "/favorite/resumes",
    component: () => import("/var/www/jobeek/pages/favorite/resumes.vue")
  },
  {
    name: "favorite-vacancies",
    path: "/favorite/vacancies",
    component: () => import("/var/www/jobeek/pages/favorite/vacancies.vue")
  },
  {
    name: "favorites-has-favs",
    path: "/favorites-has-favs",
    component: () => import("/var/www/jobeek/pages/favorites-has-favs.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordRYeaUXs8PUMeta || {},
    component: () => import("/var/www/jobeek/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/jobeek/pages/index.vue")
  },
  {
    name: "my-resume-id",
    path: "/my-resume/:id()",
    component: () => import("/var/www/jobeek/pages/my-resume/[id].vue")
  },
  {
    name: "my-resumes",
    path: "/my-resumes",
    component: () => import("/var/www/jobeek/pages/my-resumes/index.vue")
  },
  {
    name: "my-subscriptions",
    path: "/my-subscriptions",
    component: () => import("/var/www/jobeek/pages/my-subscriptions.vue")
  },
  {
    name: "my-vacancies",
    path: "/my-vacancies",
    component: () => import("/var/www/jobeek/pages/my-vacancies/index.vue")
  },
  {
    name: "my-vacancy-id",
    path: "/my-vacancy/:id()",
    component: () => import("/var/www/jobeek/pages/my-vacancy/[id].vue")
  },
  {
    name: "notification",
    path: "/notification",
    component: () => import("/var/www/jobeek/pages/notification/index.vue")
  },
  {
    name: "profile-email-verify",
    path: "/profile/email-verify",
    component: () => import("/var/www/jobeek/pages/profile/email-verify.vue")
  },
  {
    name: "profile-employer",
    path: "/profile/employer",
    component: () => import("/var/www/jobeek/pages/profile/employer/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/jobeek/pages/profile/index.vue")
  },
  {
    name: "profile-negotiations",
    path: "/profile/negotiations",
    component: () => import("/var/www/jobeek/pages/profile/negotiations.vue")
  },
  {
    name: "profile-seeker",
    path: "/profile/seeker",
    component: () => import("/var/www/jobeek/pages/profile/seeker/index.vue")
  },
  {
    name: "profile-service-verify",
    path: "/profile/service-verify",
    component: () => import("/var/www/jobeek/pages/profile/service-verify.vue")
  },
  {
    name: "profile-service-verify2",
    path: "/profile/service-verify2",
    component: () => import("/var/www/jobeek/pages/profile/service-verify2.vue")
  },
  {
    name: "responses",
    path: "/responses",
    component: () => import("/var/www/jobeek/pages/responses.vue")
  },
  {
    name: "results",
    path: "/results",
    meta: resultsmci3uAcIVUMeta || {},
    component: () => import("/var/www/jobeek/pages/results.vue")
  },
  {
    name: "resumes",
    path: "/resumes",
    component: () => import("/var/www/jobeek/pages/resumes.vue"),
    children: [
  {
    name: "resumes-slug",
    path: ":slug()",
    component: () => import("/var/www/jobeek/pages/resumes/[slug]/index.vue")
  }
]
  },
  {
    name: "search-resumes",
    path: "/search/resumes",
    component: () => import("/var/www/jobeek/pages/search/resumes.vue")
  },
  {
    name: "search-vacancies",
    path: "/search/vacancies",
    component: () => import("/var/www/jobeek/pages/search/vacancies.vue")
  },
  {
    name: "services-search-phone",
    path: "/services/search-phone",
    component: () => import("/var/www/jobeek/pages/services/search-phone.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/var/www/jobeek/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/var/www/jobeek/pages/sign-up.vue")
  },
  {
    name: "subscriptions-create",
    path: "/subscriptions/create",
    component: () => import("/var/www/jobeek/pages/subscriptions/create.vue")
  },
  {
    name: "subscriptions-edit-id",
    path: "/subscriptions/edit/:id()",
    component: () => import("/var/www/jobeek/pages/subscriptions/edit/[id].vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/var/www/jobeek/pages/subscriptions/index.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supporta3wqrQpgmRMeta || {},
    component: () => import("/var/www/jobeek/pages/support.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/www/jobeek/pages/test.vue")
  },
  {
    name: "vacancies-slug",
    path: "/vacancies/:slug()",
    component: () => import("/var/www/jobeek/pages/vacancies/[slug]/index.vue")
  },
  {
    name: "vacancies-favorite",
    path: "/vacancies/favorite",
    component: () => import("/var/www/jobeek/pages/vacancies/favorite.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    component: () => import("/var/www/jobeek/pages/vacancy.vue")
  },
  {
    name: "vee",
    path: "/vee",
    component: () => import("/var/www/jobeek/pages/vee.vue")
  },
  {
    name: "vee2",
    path: "/vee2",
    component: () => import("/var/www/jobeek/pages/vee2.vue")
  },
  {
    name: "vee3",
    path: "/vee3",
    component: () => import("/var/www/jobeek/pages/vee3.vue")
  },
  {
    name: "your-favorites",
    path: "/your-favorites",
    component: () => import("/var/www/jobeek/pages/your-favorites.vue")
  },
  {
    name: "your-messages",
    path: "/your-messages",
    component: () => import("/var/www/jobeek/pages/your-messages.vue")
  },
  {
    name: "your-responses",
    path: "/your-responses",
    component: () => import("/var/www/jobeek/pages/your-responses.vue")
  },
  {
    name: "your-resumes",
    path: "/your-resumes",
    component: () => import("/var/www/jobeek/pages/your-resumes.vue")
  }
]